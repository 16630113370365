#pickerInput {
  background: rgba(23, 23, 23, 0.05);
  border-radius: 10px;
  border-color: rgba(23, 23, 23, 0.05);
  width: 82px;
  height: 42px;
  margin-left: 5px;
  margin-right: 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  text-align-last: center;
  -moz-text-align-last: center;
}

#pickerInput:focus {
  outline: none;
}
