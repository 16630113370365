#timepickerInput {
  background: rgba(23, 23, 23, 0.05);
  border-radius: 10px;
  border-color: rgba(23, 23, 23, 0.05);
  width: 82px;
  height: 42px;
  margin-left: 5px;
  margin-right: 20px;
}

#timepickerError {
  display: none;
  visibility: hidden;
}
