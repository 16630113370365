#waveform {
    cursor: pointer;
    position: relative;
    overflow-y: hidden;

    .mask {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff; /* or whatever your background color is */
        pointer-events: none; /* this allows clicks through the mask */
    }
}
#hover {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10000;
    pointer-events: none;
    height: 100%;
    width: 0;
    mix-blend-mode: overlay;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0;
    transition: opacity 0.2s ease;
}
#waveform:hover #hover {
    opacity: 1;
}
#time,
#duration {
    position: absolute;
    z-index: 100010;
    bottom: 70px;
    margin-top: -1px;
    transform: translateY(-50%);
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
    padding: 5px;
    color: #000000;
}
#time {
    left: 0;
}
#duration {
    right: 0;
}
