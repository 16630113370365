body {
  width: 100%;
  height: 100%;
}

.OT_publisher {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.OT_subscriber {
  min-width: 20px;
  min-height: 20px;
}

.subscriberContainer {
  height: 100%;
  width: 100%;
}

.subscriberContainer div:first-child {
  height: 100%;
  width: 100%;
}

.publisherContainer {
  height: 100%;
  width: 100%;
}

.publisherContainer div:first-child {
  height: 100%;
  width: 100%;
}

.layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 70px);
  width: calc(100vw - 70px);
  padding-bottom: 1%;
}

.layoutFor2,
.layoutFor3And4,
.layoutFor5And6,
.layoutFor7And8,
.layoutFor9And10 {
  height: calc(100vh - 70px);
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  padding: 20px 12% 20px 20px;
  flex: 1;
}

.layoutScreenShare {
  height: calc(100vh - 70px);
  max-width: 325px;
  flex: .9;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 19vh;
  align-items: center;
}

#leftContainer {
  height: 100%;
  flex: 4;
}


.stream {
  position: relative;
  display: block;
  flex: 1;
  border-radius: 10px;
  margin: 3px;
  outline: 5px solid transparent;
  transition: outline 0.25s ease-in-out;
  border: 1px solid #222222;
}

.speaking {
  outline: 5px solid #1d70b6 !important;
  border-radius: 10px;
}

.stream1v1 {
  display: flex;
  min-width: 95%;
  max-width: 95%;
  max-height: 100%;
}

.streamUnder3 {
  display: flex;
  min-width: 35%;
  max-width: 48%;
  max-height: 100%;
}

.streamUnder5 {
  display: flex;
  min-width: 35%;
  max-width: 48%;
  max-height: 50%;
}

.streamUnder7 {
  display: flex;
  min-width: 32%;
  max-width: 32%;
  min-height: auto;
}

.streamUnder9 {
  display: flex;
  min-width: 32%;
  max-width: 32%;
  min-height: auto;
}

.streamUnder11 {
  display: flex;
  min-width: 32%;
  max-width: 32%;
  min-height: auto;
}

.streamUnder11 div:first-child {
  height: 100%;
  width: 100%;
  margin-bottom: 10px;
  outline: 5px solid transparent;
  transition: outline 0.25s ease-in-out;
}

.streamScreenShare {
  display: flex;
  min-width: 100%;
  min-height: 23%;
  max-height: 23.3%;
}


.OT_widget-container {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.OT_video-poster {
  display: none !important;
}

.stream div:first-child,
.streamUnder5 div:first-child,
.streamUnder7 div:first-child,
.streamUnder9 div:first-child,
.leftContainer div:first-child,
.streamScreenShare div:first-child,
.publ div:first-child {
  height: 100%;
  width: 100%;
}

.leftContainer {
  height: calc(100vh - 90px);
  flex: 4;
  padding-left: 10px;
  background-color: #222222;
}

.iconMoreThan2 {
  right: 10px;
}
