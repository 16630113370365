textarea {
  border: none;
  background-color: transparent;
  outline: none;
}

textarea::-webkit-input-placeholder {
  color: rgba(23, 23, 23, 0.3);
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(23, 23, 23, 0.3);
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(23, 23, 23, 0.3);
}

textarea:-ms-input-placeholder {
  color: rgba(23, 23, 23, 0.3);
}

textarea::placeholder {
  color: rgba(23, 23, 23, 0.3);
}

input {
  border: 0;
  outline: 0;
}
input:focus {
  outline: none !important;
}
