.App {
    text-align: center;
}

.App-logo {
    height: 40px;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.App-container {
    display: flex;
    width: 100%;
}

.App-section-publisher {
    width: 400px;
}

.App-section-subscribers {
    width: 100%;
}

video-subscribers {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    place-items: center;
}

screen-share::part(button) {
    font-size: 20px;
    color: white;
    background-color: black;
    border-radius: 5px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
